// if you need to add custom js put it in here
$(window).load(function () {
    $('.HButton').bootstrapSwitch();
    $('.about-content-wrapper').matchHeight();
    $('.blogTitle').matchHeight();
    $('.wish-list-parent').matchHeight();
    $('.pf-recent-related-product-item.product-match-height').matchHeight();
});
function CustomValidation() {
    if ($('.cart-wrapping-option-checker.EcoBand').length > 0) {
        if (!$('input[name="giftOptionEcoBand"]').is(':checked')) {
            ValidNotes('Please select a packaging option from the greeting card options above.', true);
            return false;
        }
    }
    if ($('.cart-wrapping-option-checker.Christmas').length > 0) {
        if (!$('input[name="giftOptionChristmas"]').is(':checked')) {
            ValidNotes('Please select a packaging option from the Christmas card options above.', true);
            return false;
        }
    }
    return true;
}